"use client";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";
import { Button } from "~/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import { cn } from "~/lib/utils";
import { ScrollBar } from "./scroll-area";

function SelectDropdown(props: { children: React.ReactNode }) {
  const { children } = props;
  return (
    <ScrollAreaPrimitive.Root className={cn("relative overflow-hidden")}>
      <ScrollAreaPrimitive.Viewport className="max-h-[117px] w-full rounded-[inherit]">
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
}

interface SelectSearchableProps {
  value: string | null;
  items: {
    value: string;
    label: string | React.ReactNode;
    searchableValue?: string;
  }[];
  onChange: (value: string) => void;
  placeholder?: string;
  empty: string;
  className?: string;
}

export function SelectSearchable(props: SelectSearchableProps) {
  const { value, items, onChange, placeholder, empty, className } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "justify-between h-10 min-w-[200px] border-input text-foreground pl-2 flex font-normal",
            className
          )}
        >
          {value
            ? items.find((item) => item.value === value)?.label
            : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4  opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[200px] p-0 ">
        <Command
          filter={(value, search) => {
            const item = items.find((item) => item.value === value);
            if (
              item &&
              item.searchableValue?.toLowerCase().includes(search.toLowerCase())
            ) {
              // search in searchableValue
              return 1;
            } else if (
              item &&
              item.searchableValue === undefined &&
              item.value?.toLowerCase().includes(search.toLowerCase())
            ) {
              // if no searchableValue set then search in value
              return 1;
            }

            return 0;
          }}
        >
          <CommandInput placeholder={placeholder} />
          <SelectDropdown>
            <CommandEmpty>{empty}</CommandEmpty>
            <CommandGroup>
              <CommandItem
                value={""}
                key=""
                onSelect={(currentValue) => {
                  onChange(currentValue === value ? "" : currentValue);
                  setOpen(false);
                }}
                className="py-0 cursor-pointer hover:bg-accent hover:text-accent-foreground"
              >
                <Check className={cn("mr-2 h-4 w-4 opacity-0")} />
                <span className="italic">Clear</span>
              </CommandItem>

              {items.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue) => {
                    onChange(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                  className="py-0 cursor-pointer"
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </SelectDropdown>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
